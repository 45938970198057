const colsJac = {
  exact: [ 'type' ],
  head: [ 'locale' ],
  range: [ 'gradeRange' ]
}

const weightsJac = {
  exact: [ 1.0 ],
  head: [ 1.0 ],
  range: [ 1.0 ]
}

const colsCos = [
  'aa',
  'as',
  'ca',
  'hi',
  'na',

  'exPerStu',
  'techPerStu',

  'ell',
  'freeLunch',
  'specialEd',
  'stuToTeacher',
  'size',
]

const weightsCos = [
  0.2,
  0.2,
  0.2,
  0.2,
  0.2,

  1.0,
  1.0,

  1.0,
  1.0,
  1.0,
  1.0,
  1.0,
]

const matchLocaleHead = (localeA, localeB) => {
  if (localeA === localeB){
    return 1
  }
  const a = localeA.split(', ')
  const b = localeB.split(', ')
  if (a[0] === b[0]) {
    return 0.5
  }
  else {
    return 0
  }
}

const matchGradeRange = (gradeA, gradeB) => {
  if (gradeA === gradeB) {
    return 1
  }
  const a = gradeA.replace('K', '0').split('-')
  const b = gradeB.replace('K', '0').split('-')
  if (a.length === 1 || b.length === 1) {
    return 0
  }
  let nume = Math.min(Number(a[1]), Number(b[1])) - Math.max(Number(a[0]), Number(b[0])) + 1
  if (nume < 0) {
    nume = 0
  }
  const deno = Math.max(Number(a[1]), Number(b[1])) - Math.min(Number(a[0]), Number(b[0])) + 1
  const intersect = nume / deno
  return intersect
}

const dotproduct = (u, v, w) => {
  let n = 0, lim = Math.min(u.length, v.length, w.length)
  for (let i = 0; i < lim; i++) {
    n += u[i] * v[i] * w[i] * w[i]
  }
  // console.log("dotproduct ", n)
  return n;
}

const norm2 = (u, w) => {
  let sumsqr = 0
  for (let i = 0; i < u.length; i++) {
    sumsqr += u[i] * u[i] * w[i] * w[i]
  }
  // console.log("norm2 ", Math.sqrt(sumsqr))
  return Math.sqrt(sumsqr)
}
const similarity = (u, v, w) => {
  // console.log("simCos ", dotproduct(u, v, w) / norm2(u, w) / norm2(v, w))
  return dotproduct(u, v, w) / norm2(u, w) / norm2(v, w)
}

const filterCosine = (cosine, colsCos) => {
  return Object.keys(cosine)
  .filter(key => colsCos.includes(key))
  .reduce((obj, key) => {
    return {
      ...obj,
      [key]: cosine[key]
    }
  }, {})
}

const calculateSimilarity = (yourSchool, schoolList, colsJac, weightsJac, colsCos, weightsCos) => {
  const weightsJacAll = weightsJac.exact.concat(weightsJac.head, weightsJac.range)
  const totalJac = weightsJacAll.length > 0 ? weightsJacAll.reduce((accumulator, currentValue) => accumulator + currentValue) : 0
  const totalCos = weightsCos.length > 0 ? weightsCos.reduce((accumulator, currentValue) => accumulator + currentValue) : 0
  const total = totalJac + totalCos
  const alpha = totalJac / total
  const beta = totalCos / total

  let schoolListUpdated = [...schoolList]

  for (let school in schoolList) {
    if (schoolList.hasOwnProperty(school)) {
      // Jaccard
      let simJac = 0.0
      if (alpha > 0) {
        let count = 0.0
        for (let i = 0; i < colsJac.exact.length; i++) {
          let col = colsJac.exact[i]
          if (yourSchool.jaccard[col] === schoolList[school].jaccard[col]) {
            count += weightsJac.exact[i]
          }
        }
        for (let i = 0; i < colsJac.head.length; i++) {
          let col = colsJac.head[i]
          count += matchLocaleHead(yourSchool.jaccard[col], schoolList[school].jaccard[col]) * weightsJac['head'][i]
        }
        for (let i = 0; i < colsJac.range.length; i++) {
          let col = colsJac.range[i]
          count += matchGradeRange(yourSchool.jaccard[col], schoolList[school].jaccard[col]) * weightsJac['range'][i]
        }
        simJac = count / totalJac
      }
      
      // Consine
      let simCos = 0.0
      if (beta > 0) {
        const u = Object.values(filterCosine(yourSchool.cosine, colsCos))
        const v = Object.values(filterCosine(schoolList[school].cosine, colsCos))
        const w = weightsCos
        // console.log(similarity(u, v, w))
        simCos = (similarity(u, v, w) + 1.0) / 2.0
      }
      
      // console.log("simJac: ", simJac)
      // console.log("simCos: ", simCos)
      // console.log("alpha: ", alpha, "beta: ", beta)
      // Combine
      let simCombine = (alpha * simJac + beta * simCos) * 10.0
      if (simCombine >= 10.0) {
        simCombine = 9.9999
      }
      // console.log("simCombine: ", simCombine)
      schoolListUpdated[school].score = simCombine
    }
  }

  return schoolListUpdated
}

const copyObject = object => {
  return Object.keys(object).reduce((obj, key) => {
    return {
      ...obj,
      [key]: [...object[key]]
    }
  }, {})
}

const flatObject = input => {
  const flat = (res, key, val, pre = '') => {
    const prefix = [pre, key].filter(v => v).join('.')
    return typeof val === 'object'
      ? Object.keys(val).reduce((prev, curr) => flat(prev, curr, val[curr], prefix), res)
      : Object.assign(res, { [key]: val})
  }

  return Object.keys(input).reduce((prev, curr) => flat(prev, curr, input[curr]), {})
}

const filterFactor = factorList => {
  let colsJacFiltered = copyObject(colsJac)
  let weightsJacFiltered = copyObject(weightsJac)
  Object.keys(colsJacFiltered).forEach(type => {
    colsJacFiltered[type].forEach((key, i) => {
      if (!factorList[key]) {
        delete colsJacFiltered[type][i]
        delete weightsJacFiltered[type][i]
      }
    })
    colsJacFiltered[type] = colsJacFiltered[type].filter(e => e)
    weightsJacFiltered[type] = weightsJacFiltered[type].filter(e => e)
  })

  let colsCosFiltered = [ ...colsCos ]
  let weightsCosFiltered = [ ...weightsCos ]
  colsCosFiltered.forEach((key, i) => {
    if (!factorList[key]) {
      delete colsCosFiltered[i]
      delete weightsCosFiltered[i]
    }
  })
  colsCosFiltered = colsCosFiltered.filter(e => e)
  weightsCosFiltered = weightsCosFiltered.filter(e => e)

  return [ colsJacFiltered, weightsJacFiltered, colsCosFiltered, weightsCosFiltered ]
}

export const calculateSimScore = (currentUserSchool, schoolList, factorList) => {
  const [ colsJacFiltered, weightsJacFiltered, colsCosFiltered, weightsCosFiltered ] = Object.keys(factorList).length > 0 ? filterFactor(flatObject(factorList)) : [ colsJac, weightsJac, colsCos, weightsCos ]
  const result = calculateSimilarity(currentUserSchool, schoolList, colsJacFiltered, weightsJacFiltered, colsCosFiltered, weightsCosFiltered)
  // console.log(result)
  return result
}
