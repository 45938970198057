import React from 'react'

export const renderSchoolInfo = school => {
  const labels = [
    'School Type',
    'City/Town/Suburb/Rural',
    'Enrollment Size',
    'Student to Teacher Ratio',
    'Free/Reduced Lunch %',
    'ELL Enrollment %',
    'Special Education %',
    'Expenditure per Student',
    'Tech Budget per Student',
    'Asian %',
    'Caucasian %',
    'Hispanic %',
    'African American %',
    'Native American %'
  ]
  const values = [
    `${school.info.type}, ${school.info.gradeRange} Grades`,
    school.info.locale,
    school.info.size,
    school.info.stuToTeacher,
    school.info.freeLunch,
    school.info.ell,
    school.info.specialEd,
    school.info.exPerStu,
    school.info.techPerStu,
    school.ethnicity.as,
    school.ethnicity.ca,
    school.ethnicity.hi,
    school.ethnicity.aa,
    school.ethnicity.na
  ]
  return (
    <div>
      {labels.map((label, i) => (
        <p key={ i }><span>{ label }:</span>{ values[i] }</p>
      ))}
    </div>
  )
}
