import { connect } from 'react-redux'
import { switchUserSchool, switchGrade, switchSubject, switchGeo, toggleSchoolList } from '../../actions'
import * as grades from '../../constants/grades'
import ActionBar from './ActionBar'

const getGradeList = range => {
  const rangeArr = range.replace('K', '0').split('-')
  let gradeList = [ grades.GRADE_KEY_ALL ]
  for (let i = parseInt(rangeArr[0]); i <= parseInt(rangeArr[1]); i++) {
    gradeList.push(grades.GRADE_KEYS[i])
  }
  return gradeList
}

const getCurrentSchool = (userSchoolList, currentUserSchoolId) =>{
  const currentUserSchool = userSchoolList.find(school => school.id === currentUserSchoolId)
  const gradeList = getGradeList(currentUserSchool.info.gradeRange)

  return {
    id: currentUserSchool.id,
    name: currentUserSchool.name,
    gradeList
  }
}

const mapStateToProps = state => {
  const { currentUserSchoolId, userSchoolList, currentGradeKey, currentSubjectKey, currentGeoKey } = state.tab
  return {
    currentUserSchool: getCurrentSchool(userSchoolList, currentUserSchoolId),
    userSchoolList,
    currentGradeKey,
    currentSubjectKey,
    currentGeoKey,
    isSchoolListShowing: state.school.isSchoolListShowing,
    factorList: state.factor.factorList
  }
}

const mapDispatchToProps = dispatch => ({
  onSchoolSelect: id => dispatch(switchUserSchool(id)),
  onGradeSelect: key => dispatch(switchGrade(key)),
  onSubjectSelect: key => dispatch(switchSubject(key)),
  onGeoSelect: key => dispatch(switchGeo(key)),
  onSchoolListToggle: () => dispatch(toggleSchoolList())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionBar)
