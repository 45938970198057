import { combineReducers } from 'redux'

import school from './school'
import tab from './tab'
import factor from './factor'
import chat from './chat'
import user from './user'
import temp from './temp'

export default combineReducers({
  school,
  tab,
  factor,
  chat,
  user,
  temp
})
