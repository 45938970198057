import React from 'react'

import { Tag, Tooltip } from 'antd'

const SideListTag = ({ label, isHigher }) => {
  const title = `${ isHigher ? "Higher" : "Lower" } ${ label.toLowerCase() } than your school in the latest STAR test`
  return (
    <Tooltip title={ title } placement="topLeft" overlayClassName="SideListTag-overlay">
      <Tag color={ isHigher ? "green" : "volcano" }>
        { label }
      </Tag>
    </Tooltip>
  )
}

export default SideListTag
