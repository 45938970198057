import { connect } from 'react-redux'
import { switchPerformance } from '../../actions'

import ChartDropdown from './ChartDropdown'

const mapStateToProps = state => ({
  currentPerformanceKey: state.tab.currentPerformanceKey
})

const mapDispatchToProps = dispatch => ({
  onPerformanceSelect: key => dispatch(switchPerformance(key))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChartDropdown)
