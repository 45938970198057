// Tab
export const SWITCH_USER_SCHOOL = 'SWITCH_USER_SCHOOL'
export const SWITCH_GRADE = 'SWITCH_GRADE'
export const SWITCH_SUBJECT = 'SWITCH_SUBJECT'
export const SWITCH_GEO = 'SWITCH_GEO'
export const SWITCH_PERFORMANCE = 'SWITCH_PERFORMANCE'

// School
export const TOGGLE_SCHOOL_LIST = 'TOGGLE_SCHOOL_LIST'
export const SWITCH_SCHOOL_SORTER = 'SWITCH_SCHOOL_SORTER'
export const TOGGLE_SCHOOL = 'TOGGLE_SCHOOL'
export const TOGGLE_COMPARE_MODAL = 'TOGGLE_COMPARE_MODAL'
export const CALCULATE_SCHOOL_LIST = 'CALCULATE_SCHOOL_LIST'
export const TOGGLE_SPIN = 'TOGGLE_SPIN'

// Factor
export const TOGGLE_FACTOR_MODAL = 'TOGGLE_FACTOR_MODAL'
export const UPDATE_FACTOR_LIST = 'UPDATE_FACTOR_LIST'

// User
export const TOGGLE_USER_MODAL = 'TOGGLE_USER_MODAL'
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO'

// Chat
export const TOGGLE_CHAT_MODAL = 'TOGGLE_CHAT_MODAL'
export const SWITCH_CHAT_USER = 'SWITCH_CHAT_USER'
export const ADD_CHAT_MESSAGE = 'ADD_CHAT_MESSAGE'
