import { connect } from 'react-redux'
import { toggleChatModal } from '../../actions'
import ChatModal from './ChatModal'

const mapStateToProps = state => ({
  isChatModalShowing: state.chat.isChatModalShowing,
  currentChat: state.chat.currentChat
})

const mapDispatcheToProps = dispatch => ({
  onChatModalToggle: chat => dispatch(toggleChatModal(chat)),
})

export default connect(
  mapStateToProps,
  mapDispatcheToProps
)(ChatModal)
