import * as types from '../constants/actionTypes'
import * as grades from '../constants/grades'
import * as subjects from '../constants/subjects'
import * as geoTypes from '../constants/geoTypes'
import * as metrics from '../constants/metrics'

import data from '../data'

const initialState = {
  currentUserSchoolId: '239',
  currentSubjectKey: subjects.SUBJECT_READING,
  currentGradeKey: grades.GRADE_KEY_ALL,
  currentGeoKey: geoTypes.GEO_STATE,
  currentPerformanceKey: metrics.METRIC_PR,
  
  userSchoolList: data.userSchoolList
}

const tab = (state = initialState, action) => {
  switch (action.type) {
    case types.SWITCH_USER_SCHOOL:
      return {
        ...state,
        currentUserSchoolId: action.id,
        currentGradeKey: grades.GRADE_KEY_ALL
      }
    case types.SWITCH_GRADE:
      return {
        ...state,
        currentGradeKey: action.key
      }
    case types.SWITCH_SUBJECT:
      return {
        ...state,
        currentSubjectKey: action.key
      }
    case types.SWITCH_GEO:
      return {
        ...state,
        currentGeoKey: action.key
      }
    case types.SWITCH_PERFORMANCE:
      return {
        ...state,
        currentPerformanceKey: action.key
      }
    default:
      return state
  }
}

export default tab
