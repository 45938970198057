import * as types from '../constants/actionTypes'

export const toggleFactorModal = () => ({
  type: types.TOGGLE_FACTOR_MODAL
})

export const updateFactorList = factorList => ({
  type: types.UPDATE_FACTOR_LIST,
  factorList
})
