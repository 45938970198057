import * as types from '../constants/actionTypes'

const initialState = {
  isUserModalShowing: false,

  userInfo: {
    id: '7',
    name: 'John Watson',
    title: 'School Principal',
    level: 'District',
    email: 'john@edu.com',
    school: 'Watson School',
    district: 'Holmes District',
    location: 'Pittsburgh, PA'
  }
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_USER_MODAL:
      return {
        ...state,
        isUserModalShowing: !state.isUserModalShowing
      }
    default:
      return state
  }
}

export default user
