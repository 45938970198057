import React from 'react'

import './ScoreTip.css'

import { Icon, Tooltip } from 'antd'

const tip = "SimilarityScore represents how similar another school is to yours. We find schools that have the most overlapping characteristics to yours — the more features that overlap, the greater that schools’ SimilarityScore will be."

const ScoreTip = () => (
  <Tooltip 
    className="ScoreTip"
    overlayClassName="ScoreTip-overlay"
    placement="topLeft"
    title={ tip }
  >
    <div className="ScoreTip-text">
      <Icon type="question-circle" theme="filled" /> SimilarityScore represents how similar another school is to yours.
    </div>
  </Tooltip>
)

export default ScoreTip
