import * as types from '../constants/actionTypes'
import * as sorterTypes from '../constants/sorters'
import * as geoTypes from '../constants/geoTypes'

import data from '../data'
import { calculateSimScore } from '../services'
import { filterSchoolList } from '../utils/schoolUtil'

const updateSchoolList = (currentUserSchool, schoolList, factorList, currentGeoKey) => {
  const updatedSchoolList = calculateSimScore(currentUserSchool, schoolList, factorList)
  const updatedSchoolListFiltered = filterSchoolList(updatedSchoolList.sort((a, b) => b.score - a.score), currentGeoKey, currentUserSchool)
  const updatedSelectedSchoolList = updatedSchoolListFiltered.slice(0, 5).map(school => school.id)
  return [ updatedSchoolList, updatedSelectedSchoolList ]
}

const [ initialSchoolList, initialSelectedSchoolList ] = updateSchoolList(data.userSchoolList[0], data.schoolList, {}, geoTypes.GEO_STATE)

const initialState = {
  isCompareModalShowing: false,
  currentSchoolId: '',

  isSchoolListShowing: true,
  schoolListSorter: sorterTypes.SORTER_SIMILARITY,
  selectedSchoolList: initialSelectedSchoolList,

  isSpinShowing: false,

  schoolList: initialSchoolList,
  generalMetric: data.generalMetric
}

const school = (state = initialState, action) => {
  switch (action.type) {
    case types.SWITCH_SCHOOL_SORTER:
      return {
        ...state,
        schoolListSorter: action.sorter
      }
    case types.TOGGLE_SCHOOL_LIST:
      return {
        ...state,
        isSchoolListShowing: !state.isSchoolListShowing
      }
    case types.TOGGLE_SCHOOL:
      const schoolList = state.selectedSchoolList
      if (schoolList.includes(action.id)) {
        return {
          ...state,
          selectedSchoolList: schoolList.filter(id => id !== action.id)
        }
      } else {
        return {
          ...state,
          selectedSchoolList: [...schoolList, action.id]
        }
      }
    case types.TOGGLE_COMPARE_MODAL:
      return {
        ...state,
        currentSchoolId: action.id,
        isCompareModalShowing: !state.isCompareModalShowing
      }
    case types.CALCULATE_SCHOOL_LIST:
      const [ updatedSchoolList, updatedSelectedSchoolList ] = updateSchoolList(action.currentUserSchool, state.schoolList, action.factorList, action.currentGeoKey)
      return {
        ...state,
        isSpinShowing: true,
        schoolList: updatedSchoolList,
        schoolListSorter: sorterTypes.SORTER_SIMILARITY,
        selectedSchoolList: updatedSelectedSchoolList
      }
    case types.TOGGLE_SPIN:
      return {
        ...state,
        isSpinShowing: !state.isSpinShowing
      }
    default:
      return state
  }
}

export default school
