import * as types from '../constants/actionTypes'

export const switchSchoolSorter = sorter => ({
  type: types.SWITCH_SCHOOL_SORTER,
  sorter
})

export const toggleSchoolList = () => ({
  type: types.TOGGLE_SCHOOL_LIST
})

export const toggleSchool = id => ({
  type: types.TOGGLE_SCHOOL,
  id
})

export const toggleCompareModal = id => ({
  type: types.TOGGLE_COMPARE_MODAL,
  id
})

export const calculateSchoolList = (currentUserSchool, factorList, currentGeoKey) => ({
  type: types.CALCULATE_SCHOOL_LIST,
  currentUserSchool,
  factorList,
  currentGeoKey
})

export const toggleSpin = () => ({
  type: types.TOGGLE_SPIN
})
