import React from 'react'
import * as attributes from '../../constants/attributes'
import * as colors from '../../constants/colors'

import './InfoTable.css'

import { Button, Icon, Table } from 'antd'

const USER_SCHOOL_KEY = 'userSchool'

const renderCol = (val, row) => {
  if (typeof val === 'boolean') {
    return (<Icon type={ val ? "check" : "close" } />)
  }
  if (typeof val === 'number' && row.attr.includes('%')) {
    return val + '%'
  }
  return val
}

const InfoTable = props => {
  const {
    currentUserSchool, 
    schoolList, 
    tabWidth, 
    isModal,
    onSchoolToggle, 
    onChatModalToggle
  } = props

  let cols = [
    {
      title: 'School',
      width: 316,
      dataIndex: 'attr',
      key: 'attr',
      onCell: (record, rowIndex) => {
        if (Object.keys(record).length === 2) {
          return {
            style: {
              height: '36px',
              padding: '24px 16px 8px'
            }
          }
        }
      }
    },
    {
      title: 'Your School',
      width: 158,
      dataIndex: USER_SCHOOL_KEY,
      key: USER_SCHOOL_KEY,
      render: renderCol,
      onHeaderCell: column => ({ style: { color: colors.YELLOW } })
    }
  ]
  for (const school of schoolList) {
    const key = 'school' + school.id
    cols.push({
      title: (
        <div className="InfoTable-header">
          School <code>{school.name.toLowerCase()}</code>
          {!isModal && <Button 
            className="InfoTable-close"
            type="primary" 
            shape="circle" 
            icon="close" 
            size="small" 
            onClick={ e => onSchoolToggle(school.id) }
          />}
        </div>
      ),
      width: 158,
      dataIndex: key,
      key: key,
      render: renderCol,
      onHeaderCell: column => ({ style: { color: colors.BLUE } }),
      onCell: (record, rowIndex) => {
        if (record[key] !== record[USER_SCHOOL_KEY] && rowIndex > 4) {
          return {
            style: {
              color: colors.BLUE
            }
          }
        }
      }
    })
  }

  let width = 0
  cols.forEach(col => width += col.width)

  if (width > tabWidth) {
    cols[0].fixed = 'left'
    cols[1].fixed = 'left'
  }

  let data = []

  const attrGroups = [
    [attributes.ATTRIBUTES_METRIC, 'metricMapped'],
    'Demographic',
    [attributes.ATTRIBUTES_DEMOGRAPHIC, 'info'],
    [attributes.ATTRIBUTES_ETHNICITY, 'ethnicity'],
    'Interventions',
    [attributes.ATTRIBUTES_INTERVENTION, 'intervention']
  ]

  for (const attr of attrGroups) {
    if (!Array.isArray(attr)) {
      data.push({ key: attr, attr })
    } else {
      const keys = Object.keys(attr[0])
      for (const key of keys) {
        let row = {
          key,
          attr: attr[0][key]
        }
        for (const school of schoolList) {
          row['school'+school.id] = school[attr[1]][key]
        }
        row[USER_SCHOOL_KEY] = currentUserSchool[attr[1]][key]
        data.push(row)
      }
    }
  }

  if (!isModal) {
    let rowAction = {
      key: 'action',
      attr: ''
    }
    for (const school of schoolList) {
      rowAction['school'+school.id] = (<Button type="normal" onClick={ e => onChatModalToggle(`School ${school.name.toLowerCase()}`) }>Connect</Button>)
    }
    rowAction[USER_SCHOOL_KEY] = ''
    data.push(rowAction)
  }

  return (
    <div className="InfoTable">
      <Table
        columns={ cols }
        dataSource={ data }
        scroll={{ x: width, y: (647 - 40 - 53 - 1) }}
        pagination={ false }
        onRow={record => {
          if (Object.keys(record).length === 2) {
            return {
              style: {
                height: '36px',
                fontWeight: 700,
                backgroundColor: colors.LIGHTGRAY
              }
            }
          }
          return {}
        }}
      />
    </div>
  )
}

export default InfoTable
