import React from 'react'

import './ChartMetric.css'

const ChartMetric = props => {
  const { left, top } = props
  return (
    <div 
        className="ChartMetric"
        style={{ 
          left: left,
          top: top
        }}
      >
        { props.children }
      </div>
  )
}

export default ChartMetric
