import React, { useState } from 'react'

import './SideList.css'

import { List } from 'antd'
import SideListHeader from './SideListHeader'
import SideListItem from './SideListItem'


const SideList = props => {
  const {
    currentUserSchool,
    schoolList,
    selectedSchoolList,
    onSchoolToggle,
    sorter,
    onSorterSelect,
    onFactorModalToggle
  } = props

  const [ currentPage, setCurrentPage ] = useState(1)

  const paginationConfig = {
    size: 'small',
    total: schoolList.length,
    pageSize: 6,
    current: currentPage,
    onChange: (page, pageSize) => {
      setCurrentPage(page)
    }
  }

  const handleSorterSelect = key => {
    onSorterSelect(key)
    setCurrentPage(1)
  }

  const renderItem = item => {
    return (
      <SideListItem
        key={ item.id }
        item={ item }
        currentUserSchool={ currentUserSchool }
        isChecked={ selectedSchoolList.includes(item.id) }
        onSchoolToggle={ onSchoolToggle }
      />
    )
  }

  return (
    <List
      className="SideList"
      header={
        <SideListHeader 
          sorter={ sorter } 
          onSorterSelect={ handleSorterSelect }
          onFactorModalToggle={ onFactorModalToggle }
        />
      }
      bordered
      pagination={ paginationConfig }
      dataSource={ schoolList }
      renderItem={ renderItem }
    />
  )
}

export default SideList
