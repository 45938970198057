import React from 'react'

import { Checkbox, Collapse, Icon, List, Popover } from 'antd'
import SideListTag from './SideListTag'

import { renderSchoolInfo } from '../../utils/renderer'

const { Panel } = Collapse

const SideListItem = ({ item, currentUserSchool, isChecked, onSchoolToggle }) => {
  return (
    <List.Item className="SideListItem">
      <div className="SideListItem-actions">
        <Checkbox checked={ isChecked } onChange={ e => onSchoolToggle(item.id) } />
      </div>
      <div className="SideListItem-info">
        <h3>School <code>{ item.name.toLowerCase() }</code></h3>
        <div className="SideListItem-info-tags">
          <SideListTag isHigher= { item.performance >= currentUserSchool.performance } label="Performance" />
          <SideListTag isHigher= { item.growth >= currentUserSchool.growth } label="Growth" />
        </div>
        <Collapse
          className="SideListItem-info-collapse"
          bordered={ false }
          expandIcon={({ isActive }) => <Icon type="caret-right" rotate={ isActive ? 90 : 0 } />}
        >
          <Panel className="SideListItem-info-panel" header="School Details">
            { renderSchoolInfo(item) }
          </Panel>
        </Collapse>
      </div>
      <Popover overlayClassName="SideListItem-popover" placement="left" content={
        <p>{ item.score }/10.0 <b>demographically</b> similar to your school</p>
      }>
        <div className="SideListItem-score">
          <div className="SideListItem-score-number">{ item.score }</div>
          <div className="SideListItem-score-label">Similarity</div>
        </div>
      </Popover>
    </List.Item>
  )
}

export default SideListItem
