import React from 'react'

import { Button, Modal } from 'antd'
import InfoTable from '../InfoTable'

import './CompareModal.css'

const CompareModal = props => {
  const { isCompareModalShowing, currentSchoolId, schoolList, onCompareModalToggle } = props
  const compareSchoolList = schoolList.filter(school => school.id === currentSchoolId)

  const handleOk = e => {
    onCompareModalToggle('')
  }

  const handleCancel = e => {
    onCompareModalToggle('')
  }

  return (
    <Modal
      title="Compare to Your School"
      visible={ isCompareModalShowing }
      onOk={ handleOk }
      onCancel={ handleCancel }
      footer={
        <Button type="primary" size="large" onClick={ handleOk }>Close</Button>
      }
      >
      <InfoTable {...props} schoolList={ compareSchoolList } isModal={ true } />
    </Modal>
  )
}

export default CompareModal
