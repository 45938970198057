import * as geoTypes from '../constants/geoTypes'

export const filterSchoolList = (schoolList, filter, currentUserSchool) => {
  switch (filter) {
    case geoTypes.GEO_NATION:
      return schoolList
    case geoTypes.GEO_STATE:
      return schoolList.filter(school => school.state === currentUserSchool.state)
    case geoTypes.GEO_DISTRICT:
      return schoolList.filter(school => school.district === currentUserSchool.district)
    default:
      return schoolList
  }
}
