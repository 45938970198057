export const GRADE_KEY_K = 'gradeK'
export const GRADE_KEY_1 = 'grade1'
export const GRADE_KEY_2 = 'grade2'
export const GRADE_KEY_3 = 'grade3'
export const GRADE_KEY_4 = 'grade4'
export const GRADE_KEY_5 = 'grade5'
export const GRADE_KEY_6 = 'grade6'
export const GRADE_KEY_7 = 'grade7'
export const GRADE_KEY_8 = 'grade8'
export const GRADE_KEY_9 = 'grade9'
export const GRADE_KEY_10 = 'grade10'
export const GRADE_KEY_11 = 'grade11'
export const GRADE_KEY_12 = 'grade12'
export const GRADE_KEY_ALL = 'gradeAll'

export const GRADE_KEYS = [
  GRADE_KEY_K,
  GRADE_KEY_1,
  GRADE_KEY_2,
  GRADE_KEY_3,
  GRADE_KEY_4,
  GRADE_KEY_5,
  GRADE_KEY_6,
  GRADE_KEY_7,
  GRADE_KEY_8,
  GRADE_KEY_9,
  GRADE_KEY_10,
  GRADE_KEY_11,
  GRADE_KEY_12,
]

export const GRADE_LABELS = {
  [GRADE_KEY_K]: 'Grade K',
  [GRADE_KEY_1]: 'Grade 1',
  [GRADE_KEY_2]: 'Grade 2',
  [GRADE_KEY_3]: 'Grade 3',
  [GRADE_KEY_4]: 'Grade 4',
  [GRADE_KEY_5]: 'Grade 5',
  [GRADE_KEY_6]: 'Grade 6',
  [GRADE_KEY_7]: 'Grade 7',
  [GRADE_KEY_8]: 'Grade 8',
  [GRADE_KEY_9]: 'Grade 9',
  [GRADE_KEY_10]: 'Grade 10',
  [GRADE_KEY_11]: 'Grade 11',
  [GRADE_KEY_12]: 'Grade 12',
  [GRADE_KEY_ALL]: 'All Grades'
}
