import React from 'react'

import * as colors from '../../constants/colors'
import * as metrics from '../../constants/metrics'

import * as stringUtil from '../../utils/stringUtil'

import { AxisLeft, AxisBottom } from '@vx/axis'
import { Grid } from '@vx/grid'
import { Group } from '@vx/group'
import { Circle, LinePath } from '@vx/shape'
import { scaleLinear, scalePoint, scaleOrdinal } from '@vx/scale'
import { withTooltip } from '@vx/tooltip'
import { withParentSize } from '@vx/responsive'
import { Text } from '@vx/text'
import { LegendOrdinal } from '@vx/legend'

import ChartTooltip from '../ChartTooltip'
import ChartMetric from '../ChartMetric'
import ChartDropdown from '../ChartDropdown'

import './LineChart.css'

let tooltipTimeout

const LineChart = props => {
  const { currentUserSchool, schoolList, currentGradeKey, currentSubjectKey, currentGeoKey, currentPerformanceKey, generalMetric } = props;

  const generalMetricLine = generalMetric.current.line

  const schools = [...schoolList, currentUserSchool]
  const points = schools.map(school => school.metric[currentSubjectKey][currentGradeKey].map(a => ({...a, id: school.id})))
  const userSchoolId = currentUserSchool.id
  
  const x = d => d.window
  const y = d => d[currentPerformanceKey]

  const { parentWidth, parentHeight } = props

  const width = parentWidth
  const height = parentHeight
  const margin = {
    top: 40,
    bottom: 50,
    left: 50,
    right: 40
  }

  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - margin.bottom

  const xScale = scalePoint({
    domain: points[0].map(w => w.window),
    range: [0, xMax]
  })
  const yScale = scaleLinear({
    range: [yMax, 0],
    domain: [0, 100]
  })

  const color = scaleOrdinal({
    domain: [ 'Your School', 'Other School', 'Reference' ],
    range: [ colors.YELLOW, colors.BLUE, colors.GRAY ]
  })

  const renderAxisBottom = () => (
    <AxisBottom
      scale={ xScale }
      top={ yMax }
      label={ 'Window' }
      labelOffset={ -50 }
      labelProps={{
        x: xMax,
        fill: colors.BLACK,
        fontSize: 14,
        fontWeight: 700,
        textAnchor: 'middle'
      }}
      stroke={ colors.GRAY }
      strokeDasharray="4 4"
      strokeWidth={ 1 }
      tickTextFill={ colors.GRAY }
      hideTicks={ true }
      numTicks={ 6 }
      tickLabelProps={({ tick, index }) => ({
        dy: '-.08em',
        fill: colors.GRAY,
        fontSize: 14,
        textAnchor: 'middle'
      })}
    />
  )

  const renderAxisLeft = () => (
    <AxisLeft
      scale={ yScale }
      top={ 0 }
      left={ 0 }
      label={ 'Performance' }
      labelProps={{
        x: 0 + 10,
        y: 0 + 3,
        angle: 0.0000000001,
        fill: colors.BLACK,
        fontSize: 14,
        fontWeight: 700,
        textAnchor: 'start'
      }}
      stroke={ colors.GRAY }
      strokeDasharray="4 4"
      strokeWidth={ 1 }
      tickTextFill={ colors.GRAY }
      hideTicks={ true }
      numTicks={ 6 }
      tickLabelProps={({ tick, index }) => ({
        dx: '0em',
        dy: '0.25em',
        fill: colors.GRAY,
        fontSize: 14,
        textAnchor: 'end'
      })}
    />
  )

  return (
    <div>
      <svg width={ width } height={ height }>
        <rect width={ width } height={ height } fill="#FFF" />
        <Group
          top={ margin.top } left={ margin.left }
          onTouchStart={event => {
            if (tooltipTimeout) clearTimeout(tooltipTimeout)
            props.hideTooltip()
          }}
        >
          <Grid
            xScale={ xScale }
            yScale={ yScale }
            width={ xMax }
            height={ yMax }
            numTicksRows={ 6 }
            numTicksColumns={ 6 }
          />
          
          { renderAxisBottom() }
          { renderAxisLeft() }
          
          {
            points.map((d, i) => {
              const tooltipX = xScale(x(d[3]))
              const tooltipY = yScale(y(d[3]))
              const tooltipWidth = 272
              const tooltipHeight = 273
              const isLeft = tooltipX + tooltipWidth > xMax + margin.right
              const isTop = tooltipY + tooltipHeight > yMax
              return (
                <Group className="LineChart-line" key={`lines-${i}`}>
                  <LinePath
                    data={ d }
                    x={d => xScale(x(d))}
                    y={d => yScale(y(d))}
                    stroke={ d[0].id === userSchoolId ? colors.YELLOW : colors.BLUE }
                    strokeWidth={ 1 }
                    onMouseEnter={event => {
                      console.log(isLeft, isTop)
                      if (tooltipTimeout) clearTimeout(tooltipTimeout)
                      props.showTooltip({
                        tooltipLeft: tooltipX + margin.left + (isLeft ? -0 : 0),
                        tooltipTop: tooltipY + margin.top + (isTop ? 28 : -20),
                        tooltipData: schools[i]
                      })
                    }}
                    onMouseLeave={event => {
                      tooltipTimeout = setTimeout(() => {
                        props.hideTooltip()
                      }, 1000)
                    }}
                    onTouchStart={event => {
                      if (tooltipTimeout) clearTimeout(tooltipTimeout)
                      props.showTooltip({
                        tooltipLeft: tooltipX,
                        tooltipTop: tooltipY - 30,
                        tooltipData: schools[i]
                      })
                    }}
                  />
                  <Text
                    fill={ d[0].id === userSchoolId ? colors.YELLOW : colors.BLUE }
                    x={ xMax + 8 }
                    y={ yScale(y(d[5])) }
                    dy="1.2em"
                    fontSize={ 14 }
                    textAnchor="end"
                    style={{ pointerEvents: 'none' }}
                  >
                    { `School ${schools[i].name.toLowerCase()}` }
                  </Text>
                  {d.map((point, i) => {
                    const xPos = xScale(x(point))
                    const yPos = yScale(y(point))
                    return (
                      <Group key={ i }>
                        <Text
                          fill={ colors.GRAY }
                          x={ xPos }
                          y={ yPos }
                          dy="-.8em"
                          fontSize={ 12 }
                          textAnchor="middle"
                          style={{ pointerEvents: 'none' }}
                        >
                          {y(point)}
                        </Text>
                        <Circle
                          key={`point-${point.x}-${i}`}
                          cx={ xPos }
                          cy={ yPos }
                          r={ 4 }
                          fill={ point.id === userSchoolId ? colors.YELLOW : colors.BLUE }
                          stroke={ point.id === userSchoolId ? colors.YELLOW : colors.BLUE }
                        />
                      </Group>
                    )
                  })}
                </Group>
              );
            })
          }
          <Group key={`lines-general`}>
            <LinePath
              data={ generalMetricLine }
              x={generalMetricLine => xScale(x(generalMetricLine))}
              y={generalMetricLine => yScale(y(generalMetricLine))}
              stroke={ colors.GRAY }
              strokeDasharray="4 4"
              strokeWidth={ 1 }
            />
            <Text
              fill={ colors.GRAY }
              x={ 8 }
              y={ yScale(y(generalMetricLine[0])) - 20 }
              dy="1.2em"
              fontSize={ 14 }
              textAnchor="start"
              style={{ pointerEvents: 'none' }}
            >
              {`${stringUtil.capitalize(currentGeoKey)} ${metrics.METRIC_LABELS[currentPerformanceKey]} Median`}
            </Text>
            {generalMetricLine.map((point, i) => {
              const xPos = xScale(x(point))
              const yPos = yScale(y(point))
              return (
                <Group key={ i }>
                  <Text
                    fill={ colors.GRAY }
                    x={ xPos }
                    y={ yPos }
                    dy="-.8em"
                    fontSize={ 0 }
                    textAnchor="middle"
                    style={{ pointerEvents: 'none' }}
                  >
                    {y(point)}
                  </Text>
                  <Circle
                    key={`point-${point.x}-${i}`}
                    className="dot"
                    cx={ xPos }
                    cy={ yPos }
                    r={ 0 }
                    fill={ point.id === userSchoolId ? colors.YELLOW : colors.BLUE }
                  />
                </Group>
              )
            })}
          </Group>
        </Group>
      </svg>
      
      <ChartMetric left={ margin.left + 10 } top={ margin.top - 30 }>
        <ChartDropdown />
      </ChartMetric>
      
      <ChartMetric left={ margin.left + xMax - 51 } top={ margin.top + yMax - 48 }>
        Assessment
      </ChartMetric>

      <ChartTooltip {...props} 
        isSpecial={ (props.tooltipData && props.tooltipData.id) === userSchoolId }
        clearTooltipTimeout = {() => {if (tooltipTimeout) clearTimeout(tooltipTimeout)}}
      />

      <div className="LineChart-legend" style={{ top: margin.top / 2 - 10 }}>
        <LegendOrdinal
          direction="column" 
          labelMargin="0 15px 0 0"
          scale={ color } 
          shapeHeight={ 3 }
        />
      </div>
    </div>
  )
}

export default withParentSize(withTooltip(LineChart))
