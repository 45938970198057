import { connect } from 'react-redux'
import { toggleFactorModal, toggleChatModal, toggleUserModal, calculateSchoolList, toggleSpin } from '../../actions'
import { filterSchoolList } from '../../utils/schoolUtil'
import App from './App'

let gradeKey
let subjectKey
let geoKey
let performanceKey

const getCurrentSchool = (userSchoolList, currentUserSchoolId) =>{
  const currentUserSchool = userSchoolList.find(school => school.id === currentUserSchoolId)
  const name = currentUserSchool.name
  const schoolMapped = mapSchool(currentUserSchool)
  return { ...schoolMapped, name }
}

const mapToRange5Percent = val => {
  const base = parseInt(val / 5) * 5
  return `${base}%-${base + (base === 95 ? 5 : 4)}%`
}

const mapToRangeSize = val => {
  const bases = [ 2500, 1000, 500, 300, 200, 100, 1 ]
  const ranges = [ '2,500+', '1,000-2,499', '500-999', '300-499', '200-299', '100-199', '1-99' ]
  for (let i = 0; i < bases.length; i++) {
    const base = bases[i]
    if (val > base) {
      return ranges[i]
    }
  }
}

const mapSchool = school => {
  const newestMetric = school.metric[subjectKey][gradeKey][5]
  const score = school.score.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0]
  return {
    ...school,
    name: school.codeName,
    score: score,
    ethnicity: {
      as: mapToRange5Percent(school.ethnicity.as),
      ca: mapToRange5Percent(school.ethnicity.ca),
      hi: mapToRange5Percent(school.ethnicity.hi),
      aa: mapToRange5Percent(school.ethnicity.aa),
      na: mapToRange5Percent(school.ethnicity.na)
    },
    info: {
      ...school.info,
      size: mapToRangeSize(school.info.size),
      freeLunch: mapToRange5Percent(school.info.freeLunch),
      ell: mapToRange5Percent(school.info.ell),
      specialEd: mapToRange5Percent(school.info.specialEd),
      stuToTeacher: school.info.stuToTeacher + ':1'
    },
    metricMapped: {
      score: score,
      pr: newestMetric.pr,
      nce: newestMetric.nce,
      sgp: newestMetric.sgp,
      starTestPercent: parseInt(newestMetric.starTestPercent.toFixed(0)),
      starTestDate: (new Date(newestMetric.starTestDate * 1000)).toLocaleDateString(),
    },
    performance: newestMetric[performanceKey],
    growth: newestMetric.sgp
  }
}

const mapSchoolList = schoolList => {
  return schoolList.map(mapSchool)
}

const mapStateToProps = state => {
  const { currentUserSchoolId, userSchoolList, currentGradeKey, currentSubjectKey, currentGeoKey, currentPerformanceKey } = state.tab
  gradeKey = currentGradeKey
  subjectKey = currentSubjectKey
  geoKey = currentGeoKey
  performanceKey = currentPerformanceKey
  const currentUserSchool = getCurrentSchool(userSchoolList, currentUserSchoolId)

  const { schoolList } = state.school
  const schoolListMapped = mapSchoolList(schoolList)
  const schoolListFiltered = filterSchoolList(schoolListMapped, currentGeoKey, currentUserSchool)

  return {
    isSchoolListShowing: state.school.isSchoolListShowing,
    currentUserSchool,
    schoolList: schoolListFiltered,
    isSpinShowing: state.school.isSpinShowing
  }
}

const mapDispatcheToProps = dispatch => ({
  onFactorModalToggle: () => dispatch(toggleFactorModal()),
  onChatModalToggle: chat => dispatch(toggleChatModal(chat)),
  onUserModalToggle: () => dispatch(toggleUserModal()),
  onSchoolListCalculate: (currentUserSchool, factorList) => dispatch(calculateSchoolList(currentUserSchool, factorList, geoKey)),
  onSpinToggle: () => dispatch(toggleSpin())
})

export default connect(
  mapStateToProps,
  mapDispatcheToProps
)(App)
