import React from 'react'
import * as metrics from '../../constants/metrics'

import { Dropdown, Icon, Menu } from 'antd'

import './ChartDropdown.css'

const options = [ 'pr', 'nce' ]

const ChartDropdown = props => {
  const { currentPerformanceKey, onPerformanceSelect } = props

  const menu = (
    <Menu onClick={
      ({ item, key, keyPath, domEvent }) => {
        onPerformanceSelect(key)
      }
    }>
      {options.map((m, i) => (
        <Menu.Item key={ m }>
          { metrics.METRIC_LABELS[m] }
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown className="ChartDropdown" overlay={menu}>
      <div>
        { metrics.METRIC_LABELS[currentPerformanceKey] } <Icon type="down" />
      </div>
    </Dropdown>
  )
}

export default ChartDropdown
