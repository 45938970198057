import * as types from '../constants/actionTypes'

export const switchUserSchool = id => ({
  type: types.SWITCH_USER_SCHOOL,
  id
})

export const switchGrade = key => ({
  type: types.SWITCH_GRADE,
  key
})

export const switchSubject = key => ({
  type: types.SWITCH_SUBJECT,
  key
})

export const switchGeo = key => ({
  type: types.SWITCH_GEO,
  key
})

export const switchPerformance = key => ({
  type: types.SWITCH_PERFORMANCE,
  key
})
