import React, { useState } from 'react'
import { ATTRIBUTES } from '../../constants/attributes'

import { Button, Checkbox, Col, Divider, Modal, Row } from 'antd'

import './FactorModal.css'

const copyObject = object => {
  return Object.keys(object).reduce((obj, key) => {
    const val = object[key]
    if (typeof val !== 'object') {
      obj[key] = val
    } else {
      obj[key] = Object.assign({}, val)
    }
    return obj
  }, {})
}

const FactorModal = props => {
  const {
    isFactorModalShowing, 
    onFactorModalToggle, 
    factorList, 
    onFactorListUpdate,
    onSchoolListCalculate,
    currentUserSchool,
    onSpinToggle
  } = props

  const [ localFactorList, setLocalFactorList ] = useState(copyObject(factorList))

  const keys = Object.keys(localFactorList)
  const keysChecked = keys.filter(key => {
    const val = localFactorList[key]
    if (typeof val === 'boolean' && val) {
      return key
    } else if (typeof val === 'object') {
      const subKeys = Object.keys(val)
      if (subKeys.filter(subKey => val[subKey]).length === subKeys.length) {
        return key
      }
    }
    return null
  })
  const indeterminate = keysChecked.length > 0 && keysChecked.length < keys.length
  const checkedAll = keysChecked.length === keys.length

  const handleOk = e => {
    onFactorModalToggle('')
    setTimeout(() => {
      onSpinToggle()
    }, 1500)
    onFactorListUpdate(localFactorList)
    onSchoolListCalculate(currentUserSchool, localFactorList)
  }

  const handleCancel = e => {
    onFactorModalToggle('')
    setLocalFactorList(factorList)
  }

  const handleChange = e => {
    const key = e.target.value
    const keys = key.split('.')
    const checked = e.target.checked

    let updatedFactorList = copyObject(localFactorList)
    if (keys.length === 2) {
      updatedFactorList[keys[0]][keys[1]] = checked
    } else {
      updatedFactorList[key] = checked
    }
    setLocalFactorList(updatedFactorList)
  }

  const handleGroupChange = e => {
    const groupKey = e.target.value
    const checked = e.target.checked
    
    let updatedFactorList = copyObject(localFactorList)
    Object.keys(updatedFactorList[groupKey]).forEach(subKey => {
      updatedFactorList[groupKey][subKey] = checked
    })
    setLocalFactorList(updatedFactorList)
  }

  const handleAllChange = e => {
    const boolVal = !checkedAll
    let updatedFactorList = copyObject(localFactorList)
    Object.keys(updatedFactorList).forEach(key => {
      const val = updatedFactorList[key]
      if (typeof val === 'boolean') {
        updatedFactorList[key] = boolVal
      } else if (typeof val === 'object') {
        Object.keys(val).forEach(subKey => {
          val[subKey] = boolVal
        })
      }
    })
    setLocalFactorList(updatedFactorList)
  }

  const renderCheckbox = (key, checked, labelKey) => (
    <Checkbox 
      key={ key }
      value={ key }
      checked={ checked }
      onChange={ handleChange }
    >
      { ATTRIBUTES[labelKey] }
    </Checkbox>
  )

  const renderCheckboxes = (factorList, start, len) => {
    const keys = Object.keys(factorList)
    return keys.map((factorKey, i) => {
      if (i >= start && i < start + len) {
        const factorVal = factorList[factorKey]
        if (typeof factorVal === 'boolean') {
          return renderCheckbox(factorKey, factorVal, factorKey)
        } else {
          const subKeys = Object.keys(factorVal)
          const subKeysChecked = subKeys.filter(subKey => factorVal[subKey])
          const indeterminate = subKeysChecked.length > 0 && subKeysChecked.length < subKeys.length
          const checkedAll = subKeysChecked.length === subKeys.length
          return (
            <div key={ factorKey }>
              <Checkbox
                className="FactorModal-checkbox-group"
                key={ factorKey }
                value={ factorKey }
                indeterminate={ indeterminate }
                checked={ checkedAll }
                onChange={ handleGroupChange }
              >
                { ATTRIBUTES[factorKey] }
              </Checkbox>
              {subKeys.map(subKey => {
                const subVal = factorList[factorKey][subKey]
                const key = factorKey+'.'+subKey
                return renderCheckbox(key, subVal, subKey)
              })}
            </div>
          )
        }
      }
      return null
    })
  }

  return (
    <Modal
      className="FactorModal"
      title="Customize Similarity Calculation"
      visible={ isFactorModalShowing }
      onOk={ handleOk }
      onCancel={ handleCancel }
      footer={
        <div>
          <Button type="normal" size="large" onClick={ handleCancel }>Cancel</Button>
          <Button type="primary" size="large" onClick={ handleOk } disabled={ !(checkedAll || indeterminate) }>Recalculate</Button>
        </div>
      }
    >
      <Row>
        <Checkbox
          indeterminate={ indeterminate }
          checked={ checkedAll }
          onChange={ handleAllChange }
        >
          All Factors
        </Checkbox>
      </Row>
      <Divider dashed style={{ margin: '8px 0' }} />
      <Row>
        <Col span={12}>
          { renderCheckboxes(localFactorList, 0, 8) }
        </Col>
        <Col span={12}>
          { renderCheckboxes(localFactorList, 8, 16) }
        </Col>
      </Row>
    </Modal>
  )
}

export default FactorModal
