import * as types from '../constants/actionTypes'

const initialState = {
  isFactorModalShowing: false,

  factorList: {
    type: true,
    locale: true,
    gradeRange: true,
    size: true,
    stuToTeacher: true,
    freeLunch: true,
    ell: true,
    specialEd: true,
    exPerStu: true,
    techPerStu: true,
    ethnicity: {
      as: true,
      ca: true,
      hi: true,
      aa: true,
      na: true
    }
  }
}

const factor = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_FACTOR_MODAL:
      return {
        ...state,
        isFactorModalShowing: !state.isFactorModalShowing
      }
    case types.UPDATE_FACTOR_LIST:
      return {
        ...state,
        factorList: {...action.factorList}
      }
    default:
      return state
  }
}

export default factor
