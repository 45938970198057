import React from 'react'

import * as colors from '../../constants/colors'
import * as metrics from '../../constants/metrics'

import * as stringUtil from '../../utils/stringUtil'

import { Icon, Popover } from 'antd'
import { AxisLeft, AxisBottom } from '@vx/axis'
import { Grid } from '@vx/grid'
import { Group } from '@vx/group'
import { Circle } from '@vx/shape'
import { scaleLinear } from '@vx/scale'
import { withTooltip } from '@vx/tooltip'
import { withParentSize } from '@vx/responsive'
import { Text } from '@vx/text'
import { Marker } from '@vx/marker'

import ChartTooltip from '../ChartTooltip'
import ChartMetric from '../ChartMetric'
import ChartDropdown from '../ChartDropdown'

import './BubbleChart.css'

let tooltipTimeout

const BubbleChart = props => {
  const { currentUserSchool, schoolList, currentGeoKey, currentPerformanceKey, generalMetric } = props;

  const points = [...schoolList, currentUserSchool]
  const userSchoolId = currentUserSchool.id

  const x = d => d.metricMapped.sgp
  const y = d => d.metricMapped[currentPerformanceKey]

  const { parentWidth, parentHeight } = props

  const width = parentWidth
  const height = parentHeight
  const margin = {
    top: 40,
    bottom: 50,
    left: 50,
    right: 40
  }

  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - margin.bottom

  const xScale = scaleLinear({
    domain: [0, 100],
    range: [0, xMax],
    clamp: true
  })
  const yScale = scaleLinear({
    domain: [0, 100],
    range: [yMax, 0],
    clamp: true
  })

  const renderAxisBottom = () => (
    <AxisBottom
      scale={ xScale }
      top={ yMax }
      label={ 'Growth' }
      labelOffset={ -50 }
      labelProps={{
        x: xMax,
        fill: colors.BLACK,
        fontSize: 14,
        fontWeight: 700,
        textAnchor: 'middle'
      }}
      stroke={ colors.GRAY }
      strokeDasharray="4 4"
      strokeWidth={ 1 }
      tickTextFill={ colors.GRAY }
      hideTicks={ true }
      numTicks={ 6 }
      tickLabelProps={({ tick, index }) => ({
        dy: '-.08em',
        fill: colors.GRAY,
        fontSize: 14,
        textAnchor: 'middle'
      })}
    />
  )

  const renderAxisLeft = () => (
    <AxisLeft
      scale={ yScale }
      top={ 0 }
      left={ 0 }
      label={ 'Performance' }
      labelProps={{
        x: 0 + 10,
        y: 0 + 3,
        angle: 0.0000000001,
        fill: colors.BLACK,
        fontSize: 14,
        fontWeight: 700,
        textAnchor: 'start'
      }}
      stroke={ colors.GRAY }
      strokeDasharray="4 4"
      strokeWidth={ 1 }
      tickTextFill={ colors.GRAY }
      hideTicks={ true }
      numTicks={ 6 }
      tickLabelProps={({ tick, index }) => ({
        dx: '0em',
        dy: '0.25em',
        fill: colors.GRAY,
        fontSize: 14,
        textAnchor: 'end'
      })}
    />
  )

  const renderLabel = (point, cx, cy) => {
    if (point.id === userSchoolId) {
      return (
        <Group>
          <Text
            fill={"#C5C5C5"}
            x={cx}
            y={cy}
            dy="-2.6em"
            fontSize={14}
            textAnchor="middle"
            style={{pointerEvents: 'none'}}
          >
            { point.name }
          </Text>
          <Text
            fill={"#FFF"}
            x={cx}
            y={cy}
            dy="0em"
            fontSize={24}
            textAnchor="middle"
            style={{pointerEvents: 'none'}}
          >
            Your
          </Text>
          <Text
            fill={"#FFF"}
            x={cx}
            y={cy}
            dy="1.2em"
            fontSize={12}
            textAnchor="middle"
            style={{pointerEvents: 'none'}}
          >
            School
          </Text>
        </Group>
      )
    } else {
      return (
        <Group>
          <Text
            fill={"#C5C5C5"}
            x={cx}
            y={cy}
            dy="-2.6em"
            fontSize={14}
            textAnchor="middle"
            style={{pointerEvents: 'none'}}
          >
            { `School ${point.name.toLowerCase()}` }
          </Text>
          <Text
            fill={"#FFF"}
            x={cx}
            y={cy}
            dy="0em"
            fontSize={24}
            textAnchor="middle"
            style={{pointerEvents: 'none'}}
          >
            { point.score }
          </Text>
          <Text
            fill={"#FFF"}
            x={cx}
            y={cy}
            dy="1.2em"
            fontSize={12}
            textAnchor="middle"
            style={{pointerEvents: 'none'}}
          >
            Similarity
          </Text>
        </Group>
      )
    }
  }

  const renderMarkers = (xMarker, yMarker, xLabel, yLabel, color, labelStrokeWidth) => {
    return (
      <Group>
        <Marker
          from={{ x: xScale(xMarker), y: 0 }}
          to={{ x: xScale(xMarker), y: yMax }}
          stroke={ color }
          strokeDasharray="4 4"
          strokeWidth={ 1 }
          label={ xLabel }
          labelAnchor="middle"
          labelStroke={ colors.WHITE }
          labelStrokeWidth={ labelStrokeWidth }
          labelDx={ 6 }
          labelDy={ 15 }
          labelFill={ color }
          labelFontSize={ 14 }
        />
        <Marker
          from={{ x: 0, y: yScale(yMarker) }}
          to={{ x: xMax, y: yScale(yMarker) }}
          stroke={ color }
          strokeDasharray="4 4"
          strokeWidth={ 1 }
          label={ yLabel }
          labelAnchor="left"
          labelStroke={ colors.WHITE }
          labelStrokeWidth={ labelStrokeWidth }
          labelDx={ 6 }
          labelDy={ 15 }
          labelFill={ color }
          labelFontSize={ 14 }
        />
      </Group>
    )
  }

  return (
    <div>
      <svg width={ width } height={ height }>
        <rect width={ width } height={ height } fill="#FFF" />
        <Group
          top={ margin.top } left={ margin.left }
          onTouchStart={event => {
            if (tooltipTimeout) clearTimeout(tooltipTimeout)
            props.hideTooltip()
          }}
        >
          {[0, 1, 2, 3].map(i => {
            const paddingH = 10
            const paddingV = 10
            const xs = [ 0 + paddingH, xMax - paddingH, 0 + paddingH, xMax - paddingH ]
            const ys = [ 0 + paddingV, 0 + paddingV, yMax - paddingV - 14, yMax - paddingV - 14 ]
            const anchors = [ 'start', 'end', 'start', 'end' ]
            const verticalAnchors = [ 'start', 'start', 'end', 'end' ]
            const pTexts = [ 'High', 'High', 'Low', 'Low' ]
            const gTexts = [ 'Low', 'High', 'Low', 'High' ]

            return (
              <Group key={ i }>
                <Text
                  fill={ colors.GRAY }
                  x={ xs[i] }
                  y={ ys[i] }
                  dy="0em"
                  fontSize={ 12 }
                  textAnchor={ anchors[i] }
                  verticalAnchor={ verticalAnchors[i] }
                  style={{pointerEvents: 'none'}}
                >
                  { `${pTexts[i]} Performance` }
                </Text>
                <Text
                  fill={ colors.GRAY }
                  x={ xs[i] }
                  y={ ys[i] }
                  dy="1.2em"
                  fontSize={ 12 }
                  textAnchor={ anchors[i] }
                  verticalAnchor={ verticalAnchors[i] }
                  style={{pointerEvents: 'none'}}
                  >
                  { `${gTexts[i]} Growth` }
                </Text>
              </Group>
            )
          })}

          <Grid
            xScale={ xScale }
            yScale={ yScale }
            width={ xMax }
            height={ yMax }
            numTicksRows={ 6 }
            numTicksColumns={ 6 }
          />
          
          { renderAxisBottom() }
          { renderAxisLeft() }

          { renderMarkers(
              generalMetric.current.sgp,
              generalMetric.current[currentPerformanceKey],
              `${stringUtil.capitalize(currentGeoKey)} SGP Median`,
              `${stringUtil.capitalize(currentGeoKey)} ${metrics.METRIC_LABELS[currentPerformanceKey]} Median`,
              colors.GRAY,
              0
            ) 
          }
          {props.tooltipOpen && 
            renderMarkers(
              props.tooltipData.growth,
              props.tooltipData.performance,
              `SGP: ${props.tooltipData.growth}`,
              `${metrics.METRIC_LABELS[currentPerformanceKey]}: ${props.tooltipData.performance}`,
              colors.BLUE,
              10
            )
          }

          {points.map((point, i) => {
            const cx = xScale(x(point))
            const cy = yScale(y(point))
            const r = 30
            const tooltipWidth = 272
            const tooltipHeight = 273
            const isLeft = cx + r + tooltipWidth > xMax + margin.right
            const isTop = cy + r + tooltipHeight > yMax + margin.bottom
            return (
              <Group key={ i }>
                <Circle
                  key={`point-${point.x}-${i}`}
                  className="BubbleChart-bubble"
                  cx={cx}
                  cy={cy}
                  r={r}
                  fill={ point.id === userSchoolId ? colors.YELLOW : colors.BLUE }
                  stroke={ point.id === userSchoolId ? colors.YELLOW : colors.BLUE }
                  onMouseEnter={event => {
                    if (tooltipTimeout) clearTimeout(tooltipTimeout)
                    props.showTooltip({
                      tooltipLeft: cx + margin.left + (isLeft ? -r : r),
                      tooltipTop: cy + margin.top + (isTop ? r + 8 : -r - 8),
                      tooltipData: { ...point, isLeft, isTop, isClosale: true }
                    })
                  }}
                  onMouseLeave={event => {
                    tooltipTimeout = setTimeout(() => {
                      props.hideTooltip()
                    }, 1000)
                  }}
                  onTouchStart={event => {
                    if (tooltipTimeout) clearTimeout(tooltipTimeout)
                    props.showTooltip({
                      tooltipLeft: cx,
                      tooltipTop: cy - 30,
                      tooltipData: point
                    })
                  }}
                />
                { renderLabel(point, cx, cy) }
              </Group>
            )
          })}
        </Group>
      </svg>
      
      <ChartMetric left={ margin.left + 10 } top={ margin.top - 30 }>
        <ChartDropdown />
      </ChartMetric>

      <ChartMetric left={ margin.left + xMax - 22 } top={ margin.top + yMax - 48 }>
        <Popover overlayClassName="BubbleChart-metric-tooltip-overlay" placement="left" content={
          <p>SGP represents Student Growth Percentile. The SGP score compares a student’s growth from one period to another with that of his or her academic peers nationwide-defined as students in the same grade with a similar scaled score history. <a href="http://doc.renlearn.com/KMNet/R00571375CF86BBF.pdf" target="_blank" rel="noopener noreferrer">Learn more about SGP.</a></p>
        }>
          <div className="BubbleChart-metric-tooltip">
            <Icon type="question-circle" theme="filled" /> SGP
          </div>
        </Popover>
      </ChartMetric>

      <ChartTooltip {...props} 
        isSpecial={ (props.tooltipData && props.tooltipData.id) === userSchoolId }
        clearTooltipTimeout = {() => {if (tooltipTimeout) clearTimeout(tooltipTimeout)}}
      />
    </div>
  )
}

export default withParentSize(withTooltip(BubbleChart))
