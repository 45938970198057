import React from 'react'

import './App.css'

import { Layout, Spin } from 'antd'
import Header from '../Header'
import ActionBar from '../ActionBar'
import Tabs from '../Tabs'
import ScoreTip from '../ScoreTip'
import SideList from '../SideList'
import FactorModal from '../FactorModal'
import ChatModal from '../ChatModal'
import UserModal from '../UserModal'
import OnboardModal from '../OnboardModal'

const { Content, Footer } = Layout

const App = props => {
  const {
    isSchoolListShowing, 
    onFactorModalToggle, 
    onChatModalToggle, 
    onUserModalToggle,
    currentUserSchool,
    schoolList,
    onSchoolListCalculate,
    isSpinShowing,
    onSpinToggle
  } = props
  return (
    <Spin size="large" tip="Calculating Similarity..." spinning={ isSpinShowing }>
      <Layout className="App">
        <Header 
          onChatModalToggle={ onChatModalToggle } 
          onUserModalToggle={ onUserModalToggle }
        />
        <Content className="App-content">
          <ActionBar 
            currentUserSchool={ currentUserSchool } 
            onSchoolListCalculate={ onSchoolListCalculate } 
            onSpinToggle={ onSpinToggle }
          />
          <Layout className="App-main-container">
            <Content
              className="App-main"
              style={{
                width: (isSchoolListShowing ? '70.84%' : '100%'),
                minWidth: (isSchoolListShowing ? '70.84%' : '100%')
              }}
            >
              <Tabs 
                tabWidth={ isSchoolListShowing ? 807 : 1124 } 
                onChatModalToggle={ onChatModalToggle } 
                currentUserSchool={ currentUserSchool }
                schoolList={ schoolList }
              />
            </Content>
            <Content
              className="App-sider"
              style={{
                display: (isSchoolListShowing ? 'block' : 'none'),
                width: (isSchoolListShowing ? '29.16%' : '0')
              }}
            >
              <SideList
                onFactorModalToggle={ onFactorModalToggle }
                currentUserSchool={ currentUserSchool }
                schoolList={ schoolList }
              /> 
            </Content>
          </Layout>
          <ScoreTip />
        </Content>
        <Footer className="App-footer">
          Enlighten Dashboard ©2019 Created by Enlighten at CMU
        </Footer>
        <FactorModal
          currentUserSchool={ currentUserSchool }
          onSchoolListCalculate={ onSchoolListCalculate }
          onSpinToggle={ onSpinToggle }
        />
        <ChatModal />
        <UserModal currentUserSchool={ currentUserSchool } />
        <OnboardModal />
      </Layout>
    </Spin>
  )
}

export default App
