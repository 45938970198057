import * as types from '../constants/actionTypes'

const initialState = {
  isChatModalShowing: false,
  currentChat: '',

  chatList: [
    {
      user: {
        id: 8,
        name: 'Amy Green',
        title: 'School Administrator',
        level: 'District',
        email: 'amy@edu.com',
      },
      chatList: [
        {
          userId: 8,
          time: '2019-07-09T12:30:09',
          message: 'Hello'
        }
      ]
    }
  ]
}

const chat = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_CHAT_MODAL:
      return {
        ...state,
        isChatModalShowing: !state.isChatModalShowing,
        currentChat: action.chat
      }
    default:
      return state
  }
}

export default chat
