import { connect } from 'react-redux'
import { toggleUserModal } from '../../actions'
import UserModal from './UserModal'

const mapStateToProps = state => ({
  isUserModalShowing: state.user.isUserModalShowing,
  userInfo: state.user.userInfo
})

const mapDispatcheToProps = dispatch => ({
  onUserModalToggle: () => dispatch(toggleUserModal()),
})

export default connect(
  mapStateToProps,
  mapDispatcheToProps
)(UserModal)
