import { connect } from 'react-redux'
import { toggleSchool, switchSchoolSorter } from '../../actions'
import * as sorterTypes from '../../constants/sorters'
import SideList from './SideList'

const sortSchoolList = (schoolList, sorter) => {
  switch (sorter) {
    case sorterTypes.SORTER_SIMILARITY:
      return schoolList.sort((a, b) => b.score - a.score)
    case sorterTypes.SORTER_RL:
      return schoolList.sort((a, b) => (b.score * 10 + b.performance + b.growth) - (a.score * 10 + a.performance + a.growth))
    case sorterTypes.SORTER_PERFORMANCE:
      return schoolList.sort((a, b) => b.performance - a.performance)
    case sorterTypes.SORTER_GROWTH:
        return schoolList.sort((a, b) => b.growth - a.growth)
    default:
      return schoolList
  }
}

const mapStateToProps = (state, ownProps) => {
  const { schoolListSorter, selectedSchoolList } = state.school
  
  return {
    schoolList: sortSchoolList(ownProps.schoolList, schoolListSorter),
    selectedSchoolList,
    sorter: schoolListSorter
  }
}

const mapDispatchToProps = dispatch => ({
  onSchoolToggle: id => dispatch(toggleSchool(id)),
  onSorterSelect: sorter => dispatch(switchSchoolSorter(sorter))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SideList)
