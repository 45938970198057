import { connect } from 'react-redux'
import { toggleFactorModal, updateFactorList } from '../../actions'
import FactorModal from './FactorModal'

const mapStateToProps = state => ({
  isFactorModalShowing: state.factor.isFactorModalShowing,
  factorList: state.factor.factorList
})

const mapDispatcheToProps = dispatch => ({
  onFactorModalToggle: () => dispatch(toggleFactorModal()),
  onFactorListUpdate: factorList => dispatch(updateFactorList(factorList))
})

export default connect(
  mapStateToProps,
  mapDispatcheToProps
)(FactorModal)
