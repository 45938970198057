export const ATTRIBUTES_METRIC = {
  score: 'Demographic Similarity',
  pr: 'Percentile Rank',
  nce: 'NCE',
  sgp: 'SGP',
  starTestPercent: 'Students Taking STAR Test %',
  starTestDate: 'Date of STAR Test'
}

export const ATTRIBUTES_ETHNICITY = {
  as: 'Asian %',
  ca: 'Caucasian %',
  hi: 'Hispanic %',
  aa: 'African American %',
  na: 'Native American %'
}

export const ATTRIBUTES_DEMOGRAPHIC = {
  type: 'Public/Private School',
  locale: 'City/Town/Suburb/Rural',
  gradeRange: 'Grade Range',
  size: 'Enrollment Number',
  stuToTeacher: 'Student to Teacher Ratio',
  freeLunch: 'Free/Reduce Lunch %',
  ell: 'ELL Enrollment %',
  specialEd: 'Special Education %',
  exPerStu: 'Expenditure per Student',
  techPerStu: 'Tech Budget per Student'
}

export const ATTRIBUTES_INTERVENTION = {
  careerTechEd: 'Career Tech Education',
  beforeAfterSchool: 'Before After School Program',
  esl: 'English as a Second Language Program',
  ap: 'Advanced Placement Program',
  myOnReader: 'Students Using myON Reader %',
  accReading: 'Students Using Accelerated Reader %',
  accMath: 'Students Using Accelerated Math %'
}

export const ATTRIBUTES = {
  state: 'State',
  city: 'City',
  district: 'District',
  ethnicity: 'Ethnicity',
  ...ATTRIBUTES_DEMOGRAPHIC,
  ...ATTRIBUTES_ETHNICITY
}
