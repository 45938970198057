import React from 'react'

import * as colors from '../../constants/colors'

import { Button } from 'antd'
import { TooltipWithBounds } from '@vx/tooltip'

import { renderSchoolInfo } from '../../utils/renderer'

import './ChartTooltip.css'

const ChartTooltip = props => {
  const { 
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    clearTooltipTimeout,
    isSpecial,
    onCompareModalToggle,
    onChatModalToggle,
    onSchoolToggle
  } = props

  const point = tooltipData

  const handleCompare = e => {
    onCompareModalToggle(point.id)
  }

  const handleConnect = e => {
    onChatModalToggle(`School ${point.name.toLowerCase()}`)
  }

  const handleClose = e => {
    onSchoolToggle(point.id)
    hideTooltip()
  }

  if (tooltipOpen) {
    return (
      <TooltipWithBounds
        style={{ backgroundColor: isSpecial ? colors.YELLOW : colors.BLUE }}
        left={ tooltipLeft }
        top={ tooltipTop }
        onMouseEnter={event => {
          clearTooltipTimeout()
        }}
        onMouseLeave={event => {
          hideTooltip()
        }}
        key={Math.random()}
      >
        <div className="ChartTooltip">
          <div className="ChartTooltip-header">
            <h4>{ `School ${point.name.toLowerCase()}` }</h4>
            {!isSpecial && (
              <div className="ChartTooltip-score">{ point.score }<small> Similarity</small></div>
            )}
          </div>
          <div className="ChartTooltip-content">
            { renderSchoolInfo(point) }
          </div>
          {!isSpecial && (
            <div className="ChartTooltip-actions">
              <Button type="normal" onClick={ handleCompare }>Compare</Button>
              <Button type="normal" onClick={ handleConnect }>Connect</Button>
            </div>
          )}
        </div>
        {!isSpecial && point.isClosale && (
          <div 
            className="ChartTooltip-close"
            style={{
              left: point.isLeft ? '300px' : '-51px',
              top: point.isTop ? '356px' : '64px'
            }}
          >
            <Button 
              type="primary" 
              shape="circle" 
              icon="close" 
              size="small" 
              onClick={ handleClose }
            />
          </div>
        )}
      </TooltipWithBounds>
    )
  }
  return null
}

export default ChartTooltip
