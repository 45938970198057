import { connect } from 'react-redux'
import { toggleSchool, toggleCompareModal } from '../../actions'
import Tabs from './Tabs'

const mapMetric = (metric, geoKey, subjectKey, gradeKey) => {
  return {
    ...metric,
    current: {
      pr: metric[geoKey][subjectKey][gradeKey][0].pr,
      nce: metric[geoKey][subjectKey][gradeKey][0].nce,
      sgp: metric[geoKey][subjectKey][gradeKey][0].sgp,
      starTestPercent: metric[geoKey][subjectKey][gradeKey][0].starTestPercent,
      line: metric[geoKey][subjectKey][gradeKey]
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { currentGradeKey, currentSubjectKey, currentGeoKey, currentPerformanceKey } = state.tab
  const { selectedSchoolList, generalMetric, isCompareModalShowing, currentSchoolId } = state.school
  
  return {
    schoolList: ownProps.schoolList.filter(school => selectedSchoolList.includes(school.id)),
    currentGradeKey,
    currentSubjectKey,
    currentGeoKey,
    currentPerformanceKey,
    generalMetric: mapMetric(generalMetric, currentGeoKey, currentSubjectKey, currentGradeKey),
    isCompareModalShowing,
    currentSchoolId
  }
}

const mapDispatchToProps = dispatch => ({
  onSchoolToggle: id => dispatch(toggleSchool(id)),
  onCompareModalToggle: id => dispatch(toggleCompareModal(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tabs)
