import React from 'react'

import { Avatar, Button, Icon, Modal } from 'antd'

import './UserModal.css'

const UserModal = props => {
  const { isUserModalShowing, userInfo, onUserModalToggle, currentUserSchool } = props

  const handleOk = e => {
    onUserModalToggle('')
  }

  const handleCancel = e => {
    onUserModalToggle('')
  }

  return (
    <Modal
      className="UserModal"
      title="User Profile"
      visible={ isUserModalShowing }
      onOk={ handleOk }
      onCancel={ handleCancel }
      footer={
        <div>
          <Button type="primary" size="large" onClick={ handleOk }>Close</Button>
        </div>
      }
    >
      <div className="UserModal-card">
        <div className="UserModal-card-header">
          <Avatar className="UserModal-avatar" icon="user" size={ 108 } />
          <h2>{ userInfo.name }</h2>
        </div>
        <div className="UserModal-card-content">
          <p><Icon type="idcard" />{ userInfo.title }</p>
          <p><Icon type="bank" />{ currentUserSchool.name }</p>
          <p><Icon type="apartment" />{ userInfo.district }</p>
          <p><Icon type="environment" />{ userInfo.location }</p>
        </div>
      </div>
    </Modal>
  )
}

export default UserModal
