import React from 'react'

import './ActionBar.css'

import * as grades from '../../constants/grades'
import * as subjects from '../../constants/subjects'
import * as geoTypes from '../../constants/geoTypes'

import { Button, Dropdown, Icon, Menu, Radio } from 'antd'

const ActionBar = props => {
  const {
    currentUserSchool,
    userSchoolList,
    onSchoolSelect,
    currentGradeKey,
    onGradeSelect,
    currentSubjectKey,
    onSubjectSelect,
    currentGeoKey,
    onGeoSelect,
    isSchoolListShowing,
    onSchoolListToggle,
    factorList,
    onSchoolListCalculate,
    onSpinToggle
  } = props

  const handleSchoolSelect = ({ key }) => {
    onSchoolSelect(key)
    if (key !== currentUserSchool.id) {
      setTimeout(() => {
        onSpinToggle()
      }, 1500)
      onSchoolListCalculate(userSchoolList.find(school => school.id === key), factorList)
    }
  }

  const userSchoolMenu = (
    <Menu onClick={ handleSchoolSelect }>
      {userSchoolList.map(school => (
        <Menu.Item key={ school.id }>
          <Icon type="bank" />
          { school.name }
        </Menu.Item>
      ))}
    </Menu>
  )

  const gradeMenu = (
    <Menu onClick={({ key }) => onGradeSelect(key)}>
      {currentUserSchool.gradeList.map(grade => (
        <Menu.Item key={ grade }>
          { grades.GRADE_LABELS[grade] }
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <div className="ActionBar">
      <div className="ActionBar-leading">
        <Dropdown className="ActionBar-control" overlay={ userSchoolMenu }>
          <Button className="ActionBar-dropdown ActionBar-dropdown-l" size="large">
            <div className="ActionBar-dropdown-leading">
              <Icon type="bank" />{ currentUserSchool.name }
            </div>
            <Icon type="down" />
          </Button>
        </Dropdown>
        <Dropdown className="ActionBar-control" overlay={ gradeMenu }>
          <Button className="ActionBar-dropdown ActionBar-dropdown-m" size="large">
            <div className="ActionBar-dropdown-leading">
              { grades.GRADE_LABELS[currentGradeKey] }
            </div>
            <Icon type="down" />
          </Button>
        </Dropdown>
        <Radio.Group
          className="ActionBar-control"
          size="large"
          defaultValue={ currentSubjectKey }
          onChange={ e => onSubjectSelect(e.target.value) }
        >
          <Radio.Button value={ subjects.SUBJECT_READING }>Reading</Radio.Button>
          <Radio.Button value={ subjects.SUBJECT_MATH }>Math</Radio.Button>
        </Radio.Group>
        <Radio.Group
          className="ActionBar-control"
          size="large"
          defaultValue={ currentGeoKey }
          onChange={ e => onGeoSelect(e.target.value) }
        >
          <Radio.Button value={ geoTypes.GEO_NATION }>Nation</Radio.Button>
          <Radio.Button value={ geoTypes.GEO_STATE }>State</Radio.Button>
          {/* <Radio.Button value={ geoTypes.GEO_DISTRICT }>District</Radio.Button> */}
        </Radio.Group>
      </div>
      <div className="ActionBar-trailing">
        <Button
          className="ActionBar-button"
          type="primary"
          size="large"
          // ghost={ isSchoolListShowing }
          onClick={ onSchoolListToggle }
        >
          { isSchoolListShowing ? 'Hide' : 'Show' } School List
        </Button>
      </div>
    </div>
  )
}

export default ActionBar
