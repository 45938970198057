import React, { useState } from 'react'

import { Button, Modal, Progress } from 'antd'

import './OnboardModal.css'

const OnboardModal = () => {
  const [ isShowing, setShowing ] = useState(true)

  const handleOk = e => {
    setShowing(false)
  }

  const handleCancel = e => {
    setShowing(false)
  }

  return (
    <Modal
      className="OnboardModal"
      title=""
      centered
      closable={ false }
      maskClosable={ false }
      visible={ isShowing }
      onOk={ handleOk }
      onCancel={ handleCancel }
      footer={
        <div>
          <Button type="primary" size="large" onClick={ handleOk }>Start Comparing!</Button>
        </div>
      }
    >
      <h1>Welcome to Enlighten Dashboard!</h1>
      <div className="OnboardModal-progress-info">
        <p>Demograpically<br />Different</p>
        <div className="OnboardModal-bubble-container">
          <div className="OnboardModal-bubble">
            <h5>School sigma23</h5>
            <h6>8.9</h6>
            <span>Similarity</span>
          </div>
        </div>
        <p>Demograpically<br />Similar</p>
      </div>
      <Progress
        strokeColor={{
          '0%': '#4BDBEE',
          '100%': '#00BCD4'
        }}
        strokeWidth={ 6 }
        percent={ 100 }
        showInfo={ false }
      />
      <div className="OnboardModal-progress-info">
        <p>0.0</p>
        <p>10.0</p>
      </div>
    </Modal>
  )
}

export default OnboardModal
