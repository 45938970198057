import React from 'react'

import { Button, Icon, Input, Modal } from 'antd'

import './ChatModal.css'

const ChatModal = props => {
  const { isChatModalShowing, currentChat, onChatModalToggle } = props

  const handleOk = e => {
    onChatModalToggle('')
  }

  const handleCancel = e => {
    onChatModalToggle('')
  }

  const defaultSubject = 'Connect Schools to Improve Growth'

  const defaultContent = `Hello ${currentChat},\n\nI see your school is similar to mine, but has higher growth. Would you be willing to exchange contact information so our schools can work together to improve?\n\nThank you!`

  const renderContent = () => {
    if (currentChat !== '') {
      return (
        <div>
          <div className="ChatModal-group">
            <Input addonBefore="To: " value={ currentChat } disabled />
          </div>
          <div className="ChatModal-group">
            <Input addonBefore="Suject: " placeholder="Message Subject" defaultValue={ defaultSubject } />
          </div>
          <div className="ChatModal-group">
            <Input.TextArea rows={14} placeholder="Message Content" defaultValue={ defaultContent } />
          </div>
        </div>
      )
    } else {
      return (
        <div className="ChatModal-empty">
          <Icon type="inbox" />
          <p>No Message in Inbox</p>
        </div>
      )
    }
  }

  const renderFooter = () => {
    if (currentChat !== '') {
      return (
        <div>
          <Button type="normal" size="large" onClick={ handleCancel }>Cancel</Button>
          <Button type="primary" size="large" onClick={ handleOk }>Send Message</Button>
        </div>
      )
    } else {
      return (
        <Button type="primary" size="large" onClick={ handleCancel }>Close</Button>
      )
    }
  }

  return (
    <Modal
      className="ChatModal"
      title="Connect School"
      destroyOnClose
      visible={ isChatModalShowing }
      onOk={ handleOk }
      onCancel={ handleCancel }
      footer={ renderFooter() }
    >
      { renderContent() }
    </Modal>
  )
}

export default ChatModal
