import React from 'react'

import './Header.css'

import { Button, Layout } from 'antd'

const Header = ({ onChatModalToggle, onUserModalToggle }) => {
  const handleChat = e => {
    onChatModalToggle('')
  }

  const handleUser = e => {
    onUserModalToggle()
  }

  return (
    <Layout.Header className="Header">
      <div className="Header-container">
        <div className="Header-title">Enlighten Dashboard</div>
        <div className="Header-actions">
          <Button shape="circle" icon="inbox" onClick={ handleChat } />
          <Button shape="circle" icon="user" onClick={ handleUser } />
        </div>
      </div>
    </Layout.Header>
  )
}

export default Header
