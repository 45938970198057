import React from 'react'

import { Button, Dropdown, Icon, Menu } from 'antd'

import * as sorterTypes from '../../constants/sorters'

const SideListHeader = ({ sorter, onSorterSelect, onFactorModalToggle }) => {
  const sorters = Object.values({...sorterTypes})

  const menu = (
    <Menu onClick={({ key }) => onSorterSelect(key)}>
      {sorters.map(sorter => (
        <Menu.Item key={ sorter }>
          { sorter }
        </Menu.Item>
      ))}
    </Menu>
  )

  const handleCustomize = e => {
    onFactorModalToggle()
  }

  return (
    <div className="SideListHeader">
      <h2>School List</h2>
      <div className="SideListHeader-actions">
        <div className="SideListHeader-leading">
          <Dropdown overlay={ menu }>
            <span className="SideListHeader-dropdown">
              Sort by: { sorter } <Icon type="down" />
            </span>
          </Dropdown>
        </div>
        <Button type="primary" size="small" ghost onClick={ handleCustomize }>
          <Icon type="setting" />Customize
        </Button>
      </div>
    </div>
  )
}

export default SideListHeader
