import React from 'react'

import './Tabs.css'

import { Tabs } from 'antd'
import BubbleChart from '../BubbleChart'
import LineChart from '../LineChart'
import TableView from '../TableView'
import CompareModal from '../CompareModal'

const { TabPane } = Tabs

const TabsView = props => (
  <div className="Tabs">
    <Tabs type="card" tabBarGutter={0} defaultActiveKey="1">
      <TabPane className="Tabs-pane" tab="SGP vs Performance" key="1">
        <BubbleChart {...props} />
      </TabPane>
      <TabPane className="Tabs-pane" tab="Longitudinal Comparison" key="2">
        <LineChart {...props} />
      </TabPane>
      <TabPane className="Tabs-pane" tab="Table View" key="3">
        <TableView {...props} />
      </TabPane>
    </Tabs>
    <CompareModal {...props} />
  </div>
)

export default TabsView
