import React from 'react'

import './TableView.css'

import InfoTable from '../InfoTable'

const TableView = props => {
  return (
    <div className="TableView">
      <InfoTable {...props} />
    </div>
  )
}

export default TableView
